.profile-page {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
}

.profile-header {
  text-align: center;
}

.profile-pic {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 20px;
}

.profile-bio {
  font-style: italic;
  color: #666;
}

.profile-details {
  margin-top: 30px;
}

.profile-details h3 {
  margin-bottom: 10px;
}

.profile-details p {
  margin: 5px 0;
}
