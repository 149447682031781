@import url("https://fonts.googleapis.com/css2?family=Matemasie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Matemasie&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

h1,
h2,
h3,
h4 {
  font-family: "Matemasie", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.mymenu {
  font-family: "Matemasie", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
}
.mymenu1 {
  font-family: "Matemasie", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}
.mymenu1 > li > Link {
  text-decoration: none;
}
.mymenu1 > li:hover {
  transform: scale(1.05);
}
